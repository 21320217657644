/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('QuerysModals',{
        queryExecute: {
            title: 'global.querys.execute.executeQuery',
            size: 'modal-xxl',
            icon: 'fa fa-cog',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'name',
                                type: 'annexaLabel',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.literals.name");
                                    if($scope.model && $scope.model.name){
                                        $scope.to.value = $scope.model.name;
                                    }
                                }
                            },
                            {
                                key: 'description',
                                type: 'annexaLabel',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.literals.description");
                                    if($scope.model && $scope.model.description){
                                        $scope.to.value = $scope.model.description;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        key: 'additionalFilter',
                        className: '',
                        templateOptions: {},
                        fieldGroup: []
                    },
                    {
                        key: 'tableZone',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'tableDataQueryField',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'table-data-query',
                                    query: undefined
                                },
                                data: {
                                    updateOnChange: false
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        reportExecute: {
            title: 'global.querys.report.executeReport',
            size: 'modal-xxl',
            icon: 'fa fa-cog',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'name',
                                type: 'annexaLabel',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, Language) {
                                    $scope.to.label = $filter("translate")("global.literals.name");
                                    if($scope.model && $scope.model.name){
                                        $scope.to.value = $scope.model.name;
                                    }
                                }
                            },
                            {
                                key: 'description',
                                type: 'annexaLabel',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.literals.description");
                                    if($scope.model && $scope.model.description){
                                        $scope.to.value = $scope.model.description;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        key: 'additionalFilter',
                        className: '',
                        templateOptions: {},
                        fieldGroup: []
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        cronScheduleForm: {
            title: 'global.commonAdmin.modal.cron.scheduleExecution',
            size: 'modal-xxl',
            icon: 'fa fa-cog',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'cronExpression',
                                type: 'annexaComponent',
                                className: 'col-sm-12',
                                templateOptions: {
                                	type: 'cron-gen',
                                	cronOptions: {},
                                	isCronDisabled: false
                                },
                                data: {
                                	cronExpression: ''
                                },
                                controller: ['$scope', 'Language', function($scope, Language) {
                                	$scope.to.cronOptions = {
                                    	hideMinutesTab: true,
                                    	hideAdvancedTab: false,
                                    	use24HourTime: true,
                                    	language: Language.getActiveLang()	
                                	};
                                	if ($scope.model.cronExpression) {
                                		$scope.options.data.cronExpression = $scope.model.cronExpression;
                                	} else {
                                		$scope.options.data.cronExpression = '0 0 8 ? * MON *';
                                	}
                                }]
                            }
                        ]
                    },
                    {
                        key: 'row2',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'sentByMail',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-2',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    options:[]
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.literals.sendByEmail')});
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    focus: true,
                                    label: 'global.literals.profile',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', function($scope, $rootScope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).distinctBy("x => x.id").toArray();
                                	$scope.model.profiles = [];
                                    if ($scope.model.profilesIds && $scope.model.profilesIds.length > 0) {
                                    	_.forEach($scope.model.profilesIds, function(profile) {
                                    		var profileOptions = $linq($scope.to.options).firstOrDefault(undefined, "x => x.id == " + profile);
                                    		
                                    		if (profileOptions) {
                                    			$scope.model.profiles.push(profileOptions);
                                    		}
	                                    });
                                    }
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: 'col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if (!scope.model.sentByMail_1) {
                                    	scope.fields[1].templateOptions.disabled = true;
                                    	scope.fields[1].templateOptions.required = false;
                                    	return true;
                                    } else {
                                    	scope.fields[1].templateOptions.disabled = false;
                                    	scope.fields[1].templateOptions.required = true;
                                    }
                                    if(scope.$parent && scope.$parent.model){
                                    	if(scope.$parent.model.row2Mail){
                                    		scope.$parent.model.row2Mail.sentByMail_1 = ((scope.model.sentByMail_1)?true:false);
                                    	}
                                    	if(scope.$parent.model.row2MailBtn){
                                    		scope.$parent.model.row2MailBtn.sentByMail_1 = ((scope.model.sentByMail_1)?true:false);
                                    	}
                                    	if(scope.$parent.model.row2MailBody){
                                    		scope.$parent.model.row2MailBody.sentByMail_1 = ((scope.model.sentByMail_1)?true:false);
                                    	}
                                    	scope.fields[1].templateOptions.required = false;
                                    }	
                                    return false;
                                }
                            }
                        ]
                    },
                    {
                        key: 'row2Mail',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
    						{
                                key: 'emailLabelTo',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: ['global.literals.emailTo']
                                },
                                data: { 
                                    informed: true,
                                    row: true,
                                    colClass: 'col-sm-12',
			                        labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
    						{
                                key: 'emailLabelCC',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: ['global.literals.emailCC']
                                },
                                data: { 
                                    informed: true,
                                    row: true,
                                    colClass: 'col-sm-12',
			                        labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
    						{
                                key: 'emailLabelCCO',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: ['global.literals.emailCCO']
                                },
                                data: { 
                                    informed: true,
                                    row: true,
                                    colClass: 'col-sm-12',
			                        labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
                            { 
                                key: 'emailsTO',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: [],
                                    canRemove: true,
                                    deleteObject: function(label,$index){}
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-value'
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
                            { 
                                key: 'emailsCC',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: [],
                                    canRemove: true,
                                    deleteObject: function(label,$index){}
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-value'
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
                            { 
                                key: 'emailsCCO',
                                type: 'annexaLabelsRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    labels: [],
                                    canRemove: true,
                                    deleteObject: function(label,$index){}
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-value'
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        key: 'row2MailBtn',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
    				        { 
                                key: 'emailsToBtn',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.addEmailTo',
                                	executeFunction: function(){},
									atributes:"btn-sm",
									type:"text",
									validate:true,
									required: false
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-strong',
			                        extralabel: true,
									informed: true
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
                            { 
                                key: 'emailsCCBtn',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.addEmailCC',
                                	executeFunction: function(){},
									atributes:"btn-sm",
									type:"text",
									validate:true,
									required: false
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-strong',
			                        extralabel: true,
									informed: true
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            },
                            { 
                                key: 'emailsCCOBtn',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.addEmailCCO',
                                	executeFunction: function(){},
									atributes:"btn-sm",
									type:"text",
									validate:true,
									required: false
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-strong',
			                        extralabel: true,
									informed: true
			                    },
			                    hideExpression: function ($viewValue, $modelValue, scope) {
			                    	if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        key: 'row2MailBody',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'body',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.emailBody',
                                    rows: 5,
                                    required: false,
                                    focus: false,
                                    maxLength: 5000
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if (!scope.model.sentByMail_1) {
                                    	return true;
                                    } else {
                                    	return false;
                                    }
                                },
                                data: { 
                                    row: true,
			                        colClass: 'col-sm-12',
			                        labelClass: 'label-strong'
			                    }
                            }
                        ]
                    },
                    {
                        key: 'row3',
                        className: 'modal-body p-lg',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'sentByFTP',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-2',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.literals.sendByFTP')});
                                }
                            },
                            {
                                key: 'host',
                                type: 'annexaInputRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.host',
                                    maxlength:100
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: '!model.sentByFTP_1',
                                expressionProperties: {
                                    'templateOptions.required': 'model.sentByFTP_1'
                                }
                            },
                            {
                                key: 'port',
                                type: 'annexaInputRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    type: 'number',
                                    label: 'global.literals.port',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: '!model.sentByFTP_1',
                                expressionProperties: {
                                    'templateOptions.required': 'model.sentByFTP_1'
                                }
                            },
                            {
                                key: 'userFtp',
                                type: 'annexaInputRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.user',
                                    maxlength:50
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: '!model.sentByFTP_1',
                                expressionProperties: {
                                    'templateOptions.required': 'model.sentByFTP_1'
                                }
                            },
                            {
                                key: 'passwordFtp',
                                type: 'annexaInputRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.password',
                                    maxlength:50
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: '!model.sentByFTP_1',
                                expressionProperties: {
                                    'templateOptions.required': 'model.sentByFTP_1'
                                }
                            },
                            {
                                key: 'folder',
                                type: 'annexaInputRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.folder',
                                    maxlength:255
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: '!model.sentByFTP_1',
                                expressionProperties: {
                                    'templateOptions.required': 'model.sentByFTP_1'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []       	
        },
        addMail: {
            title: 'global.literals.addEmail',
            size: 'modal-lg',
            icon: 'fa fa-at',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'email',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'email',
                                    label: 'global.profile.email',
                                    required: true,
                                    focus: true,
                                    updateOnBlur:true
                                }
                            }
                        ]
                    }
                ]
            }
        }
    })
;